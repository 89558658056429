var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-12"},[_c('v-row',{staticClass:"py-12"},[_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[(_vm.$i18n.locale === 'en')?_c('div',[_c('h3',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t("contactus.ContactInfo")))]),_c('p',{staticClass:"pt-12 caption",domProps:{"innerHTML":_vm._s(
            `<b class='subtitle-2'>${_vm.$t(
              'contactus.Address'
            )}</b> &nbsp;&nbsp;&nbsp;&nbsp; ${_vm.$t('contactus.FindUS')}`
          )}}),_c('p',{staticClass:"pt-8 caption",domProps:{"innerHTML":_vm._s(
            `<b class='subtitle-2' >${_vm.$t(
              'contactus.Phone'
            )}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+964-7839910007`
          )}}),_c('p',{staticClass:"pt-8 caption",domProps:{"innerHTML":_vm._s(
            ` <b class='subtitle-2' >${_vm.$t(
              'contactus.Email'
            )}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  info@fiberiraq.com <br /><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; director@fiberiraq.com`
          )}})]):_vm._e(),(_vm.$i18n.locale === 'ar')?_c('div',[_c('h3',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t("contactus.ContactInfo")))]),_c('p',{staticClass:"pt-12 caption",domProps:{"innerHTML":_vm._s(
            `<b class='subtitle-2'>${_vm.$t('contactus.Address')}</b> &nbsp; ${_vm.$t(
              'contactus.FindUS'
            )}`
          )}}),_c('p',{staticClass:"pt-8 caption",domProps:{"innerHTML":_vm._s(
            `<b class='subtitle-2' >${_vm.$t(
              'contactus.Phone'
            )}</b> &nbsp; +964 7839910007 `
          )}}),_c('p',{staticClass:"pt-8 caption",domProps:{"innerHTML":_vm._s(
            ` <b class='subtitle-2' >${_vm.$t(
              'contactus.Email'
            )}</b> &nbsp;  info@fiberiraq.com - director@fiberiraq.com`
          )}})]):_vm._e(),_c('h3',{staticClass:"secondary--text pt-12"},[_vm._v(_vm._s(_vm.$t("contactus.GetInTouch")))]),_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('contactus.yourname')}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('contactus.youremail')}})],1)],1),_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('contactus.yourtitle')}})],1)],1),_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('contactus.yourmass')}})],1)],1),_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"primary--text",attrs:{"color":"accent"}},[_vm._v(_vm._s(_vm.$t("contactus.sendmess")))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }