<template>
  <v-container class="mt-12">
    <v-row class="py-12">
      <!-- <v-col cols="12" lg="6" md="6" sm="12">
        <v-card @click="gotoMap()" elevation="0">
          <img
            style="object-fit: cover"
            width="100%"
            src="../../assets/map.png"
          />
        </v-card>
      </v-col> -->
      <v-col cols="12" lg="6" md="6" sm="12" class="px-12">
        <div v-if="$i18n.locale === 'en'">
          <h3 class="secondary--text">{{ $t("contactus.ContactInfo") }}</h3>
          <p
            class="pt-12 caption"
            v-html="
              `<b class='subtitle-2'>${$t(
                'contactus.Address'
              )}</b> &nbsp;&nbsp;&nbsp;&nbsp; ${$t('contactus.FindUS')}`
            "
          ></p>
          <p
            class="pt-8 caption"
            v-html="
              `<b class='subtitle-2' >${$t(
                'contactus.Phone'
              )}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+964-7839910007`
            "
          ></p>
          <p
            class="pt-8 caption"
            v-html="
              ` <b class='subtitle-2' >${$t(
                'contactus.Email'
              )}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  info@fiberiraq.com <br /><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; director@fiberiraq.com`
            "
          ></p>
        </div>
        <div v-if="$i18n.locale === 'ar'">
          <h3 class="secondary--text">{{ $t("contactus.ContactInfo") }}</h3>
          <p
            class="pt-12 caption"
            v-html="
              `<b class='subtitle-2'>${$t('contactus.Address')}</b> &nbsp; ${$t(
                'contactus.FindUS'
              )}`
            "
          ></p>
          <p
            class="pt-8 caption"
            v-html="
              `<b class='subtitle-2' >${$t(
                'contactus.Phone'
              )}</b> &nbsp; +964 7839910007 `
            "
          ></p>
          <p
            class="pt-8 caption"
            v-html="
              ` <b class='subtitle-2' >${$t(
                'contactus.Email'
              )}</b> &nbsp;  info@fiberiraq.com - director@fiberiraq.com`
            "
          ></p>
        </div>
        <h3 class="secondary--text pt-12">{{ $t("contactus.GetInTouch") }}</h3>
        <v-row class="pb-0">
          <v-col cols="12" lg="6" md="6" sm="12" class="pb-0">
            <v-text-field
              outlined
              :label="$t('contactus.yourname')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" class="pb-0">
            <v-text-field
              outlined
              :label="$t('contactus.youremail')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col class="py-0" cols="12" lg="12" md="12" sm="12">
            <v-text-field
              outlined
              :label="$t('contactus.yourtitle')"
            ></v-text-field> </v-col
        ></v-row>
        <v-row class="pt-0">
          <v-col class="pt-0" cols="12" lg="12" md="12" sm="12">
            <v-textarea outlined :label="$t('contactus.yourmass')"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="pt-0">
          <v-col class="pt-0" cols="12" lg="12" md="12" sm="12">
            <v-btn color="accent" class="primary--text">{{
              $t("contactus.sendmess")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  methods: {
    gotoMap() {
      window.open(
        "https://www.google.com/maps/place/%D8%B4%D8%B1%D9%83%D8%A9+%D8%B5%D8%B1%D8%AD+%D8%A7%D9%84%D8%A8%D9%84%D8%A7%D8%AF+%D9%84%D9%84%D9%85%D9%82%D8%A7%D9%88%D9%84%D8%A7%D8%AA+%D8%A7%D9%84%D8%B9%D8%A7%D9%85%D8%A9+%D8%A7%D9%84%D9%85%D8%AD%D8%AF%D9%88%D8%AF%D8%A9%E2%80%AD/@33.2910522,44.3903194,17z/data=!3m1!4b1!4m5!3m4!1s0x15577f85a2202d33:0x9926585f11cefd06!8m2!3d33.2910653!4d44.3925398"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.geeks {
  transition: 0.5s all ease-in-out;
}

.geeks:hover {
  transform: scale(1.5);
}
</style>
